<template>
  <div>
    <QuickMonitoringModal :dialog="dialog" @close="closeQuickMonitoringModal" @onChangeKind="onChangeKind" />
    <AddBodyTemperature @onSave="saveAndRedirect" />
    <AddBloodGlucose @onSave="saveAndRedirect" />
    <AddBloodPressure @onSave="saveAndRedirect" />
    <AddHeartRate @onSave="saveAndRedirect" />
    <AddOxygenSaturation @onSave="saveAndRedirect" />
    <AddRespiratoryRate @onSave="saveAndRedirect" />
    <Success :dialog="successModal" @close="successModal = false" @onSickModalOpen="sickModal = true" />
    <Sick />
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";

import { useAuthStore } from "@/pinia-store/auth";
import { useObservationsStore } from "@/pinia-store/observations";
import AddBloodGlucose from "@/views/Patient/Monitoring/bloodGlucose/Add";
import AddBloodPressure from "@/views/Patient/Monitoring/bloodPressure/Add";
import AddBodyTemperature from "@/views/Patient/Monitoring/bodyTemperature/Add";
import AddHeartRate from "@/views/Patient/Monitoring/heartRate/Add";
import AddOxygenSaturation from "@/views/Patient/Monitoring/oxygenSaturation/Add";
import QuickMonitoringModal from "@/views/Patient/Monitoring/QuickMonitoringModal";
import AddRespiratoryRate from "@/views/Patient/Monitoring/respiratoryRate/Add";
import Sick from "@/views/Patient/Monitoring/Sick";
import Success from "@/views/Patient/Monitoring/Success";

export default {
  name: "QuickMonitoring",
  components: {
    QuickMonitoringModal,
    Sick,
    Success,
    AddBloodPressure,
    AddRespiratoryRate,
    AddOxygenSaturation,
    AddHeartRate,
    AddBloodGlucose,
    AddBodyTemperature,
  },
  data: () => ({
    dialog: true,
    successModal: false,
    kind: "",
  }),
  computed: {
    ...mapState(useAuthStore, ["uid"]),
  },
  methods: {
    ...mapActions(useObservationsStore, [
      "toggleEditFormOptions",
      "toggleHeartRateFormOpened",
      "clearHeartRateForm",
      "toggleBloodPressureFormOpened",
      "clearBloodPressureForm",
      "toggleRespiratoryRateFormOpened",
      "clearRespiratoryRateForm",
      "toggleBloodGlucoseFormOpened",
      "clearBloodGlucoseForm",
      "toggleOxygenSaturationFormOpened",
      "clearOxygenSaturationForm",
      "toggleBodyTemperatureFormOpened",
      "clearBodyTemperatureForm",
    ]),
    ...mapActions(useObservationsStore, ["changeEditFormField"]),
    async closeQuickMonitoringModal() {
      this.dialog = false;
      await this.$router.push("/patient");
    },

    async onChangeKind(value) {
      this.dialog = false;
      this.kind = value;
      this.toggleHealthDataDialog(value);
      this.toggleEditFormOptions();
    },

    toggleHealthDataDialog(kind) {
      switch (kind) {
        case "bloodGlucose": {
          this.clearBloodGlucoseForm();
          this.toggleBloodGlucoseFormOpened();
          break;
        }
        case "oxygenSaturation": {
          this.clearOxygenSaturationForm();
          this.toggleOxygenSaturationFormOpened();
          break;
        }
        case "bodyTemperature": {
          this.clearBodyTemperatureForm();
          this.toggleBodyTemperatureFormOpened();
          break;
        }
        case "respiratoryRate": {
          this.clearRespiratoryRateForm();
          this.toggleRespiratoryRateFormOpened();
          break;
        }
        case "heartRate": {
          this.clearHeartRateForm();
          this.toggleHeartRateFormOpened();
          break;
        }
        case "bloodPressure": {
          this.clearBloodPressureForm();
          this.toggleBloodPressureFormOpened();
          break;
        }
      }
    },

    async saveAndRedirect() {
      this.toggleHealthDataDialog(this.kind);
      if (!this.sickModal) this.successModal = true;
      setTimeout(async () => {
        await this.$router.push("/patient");
      }, 2000);
    },
  },
};
</script>

<style scoped></style>
